import { render, staticRenderFns } from "./Preferences.vue?vue&type=template&id=5d1d1257&"
import script from "./Preferences.vue?vue&type=script&lang=ts&"
export * from "./Preferences.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  ,true
)

export default component.exports